import { logout } from "@/services/user";
import { clearCookiesCaches } from "@/utils/tools";
import { whiteList } from "@/utils/auth";

const minutes = 30
const reminderTimeInSecond = 10
let interval = null
let countdown = 0
let countdownStart = false
let callback = null

let skip = () => whiteList.includes( window.location.pathname ) || countdownStart 

let start = () => {
    // logout after 30 minutes
    if( ! skip() ) {
        clear()
        countdown = reminderTimeInSecond
        const time = ( 1000 * 60 * minutes ) - ( 1000 * reminderTimeInSecond )
        interval = setTimeout( () => reminder(), time )
    }
}

let stop = () => {
    clearInterval(interval)
    interval = null
}

let clear = () => {
    stop()
    countdown = 0
    countdownStart = false
}

let kickout = () => {
    clear()
    clearCookiesCaches()
    logout().then(() => { location.href = "/login"; })
}

let reminder = () => {
    countdownStart = true

    if( countdown < 1 ) {
        kickout()
    } else {

        stop()
        
        if( typeof callback === 'function')
            callback( countdown )

        countdown--;
        interval = setTimeout( () => reminder(), 1000 )
    }
}

const inactivity = {
    start: ( fn ) => { callback = fn; start(); },
    active: () => { clear(); start(); },
}

export default inactivity;