import VueRouter from "vue-router";
import Vue from "vue";
import store from "@/store";
import { getToken, whiteList } from "@/utils/auth";
import Layout from "/src/layout/index.vue";
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: () => import("/src/view/login"),
    },
    {
      path: "/mvpLogin",
      component: () => import("/src/view/mvp-login"),
    },
    {
      path:'/loginByCode',
      component: () => import("/src/view/mvp-login2"),
    },
    {
      path: "/activate",
      component: () => import("/src/view/activate"),
    },
    {
      path: "/register",
      component: () => import("/src/view/register"),
    },
    {
      path: "/404",
      component: () => import("/src/view/error/404"),
    },
    {
      path: "/mvp/authentication",
      component: () => import("/src/view/mvp-authentication"),
    },
    {
      path: "/pilot",
      component: Layout,
      children: [
        {
          path: "index",
          component: () => import("@/view/pilot/index.vue"),
        },
      ],
    },
  ],
});
router.beforeEach((to, form, next) => {
  const token = getToken();
  if (token) {
    if (store.state.userInfo) {
      next();
    } else {
      store.dispatch("getUserInfo").then(() => {
        next();
      });
    }
    next();
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login");
    }
  }
});

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};
export default router;
