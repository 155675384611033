<template>
    <!--div v-if="countdown > 0" style="position:fixed; top:0; width:100vw; z-index:9000">
        <div style="position:absolute; width:100vw; height:100vh; background-color: black; opacity: 0.5;"></div>
        <div style="position: relative;">
            <div style="width: 200px; margin: 15vh auto; background-color:white; border-radius: 10px;">
                <div class="pt-2 text-center" style="font-weight:bold;">Reminder</div>
                <div class="pt-1 text-center">Logout in {{ countdown }} seconds</div> 
                <div class="py-5 text-center">
                    <el-button size="mini" type="primary" @click="handleContinueClick">Continue</el-button>
                </div>
            </div>
        </div>
    </div-->
    <el-dialog :visible.sync="showModal" title="Reminder" :width="'300px'" :before-close="( done ) => done()" :close-on-click-modal="false" :show-close="false">
      <div class="text-center">Logout in {{ countdown }} seconds</div>
      <div class="mt-5 text-center">
        <el-button size="mini" type="primary" @click="handleContinueClick">Continue</el-button>
      </div>
    </el-dialog>
</template>

<script>
import Inactivity from "@/utils/inactivity";

export default {
    name: 'Inactivity',
    data() {
        return {
            countdown: 0,
            showModal: false
        }
    },
    mounted() {
        Inactivity.start( this.getCountdown )
    },
    methods: {
        getCountdown( countdown ) {
            this.countdown = countdown
            this.showModal = this.countdown > 0
        },
        refresh() { 
            Inactivity.start( this.getCountdown ) 
        },
        handleContinueClick() { 
            Inactivity.active() 
            this.showModal = false
        },
    },
};
</script>