import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "./tailwind.css";
import router from "@/router";
import store from "@/store";
import pagination from "@/components/pagination";
import "./element-variables.scss";
import "./assets/common.scss";
import "./assets/transition.scss";
import "./assets/responsiveView.scss";
import i18n from "./locale";
import VueSSE from 'vue-sse';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.component("pagination", pagination);
Vue.use(VueSSE);
import "./icons";
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
