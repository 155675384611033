import { searchProduct, addProduct, delProduct } from "@/services/user";

export function convertDateTimeStrToDate(dateTimeStr, splitMark) {
  if (!checkIsNotEmptyOrNull(dateTimeStr)) return null
    var dateTimeArr = dateTimeStr.split(" ");
    var d1 = dateTimeArr[0].split(splitMark);
    var timeStr = dateTimeArr[1];
    var d = [d1[1],d1[0],d1[2]];
    var dateArr = d.toString().replaceAll(',',splitMark)
    var formatStr = dateArr +" "+ timeStr;
  return new Date(formatStr)
}
export function checkIsNotEmptyOrNull(str) {
  if (str == null) {
    return false;
  } else {
    return str.trim() != '';
  }
}
export function sortByDate(obj1, obj2, fieldName) {
  const a = convertDateTimeStrToDate(obj1[fieldName],'/'); 
  const b = convertDateTimeStrToDate(obj2[fieldName],'/'); 
  return a - b
}
export function  sortByNumStr(obj1, obj2, fieldName ) {
  const a = checkIsNotEmptyOrNull(obj1[fieldName])?  parseInt(obj1[fieldName]) : 0
  const b = checkIsNotEmptyOrNull(obj2[fieldName])?  parseInt(obj2[fieldName]) : 0
  return a - b
}
export function clearCookiesCaches() {

  // clear caches
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
    .then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister();
        }
    });
  } 

  // clear cookies
  let allCookies = document.cookie.split(';');    
  // The "expire" attribute of every cookie is  
  // Set to "Thu, 01 Jan 1970 00:00:00 GMT" 
  for (var i = 0; i < allCookies.length; i++) 
    document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString(); 
}

export function productAutocomplete() {

  let searchCallBack = null
  let queryString = ''
  let target = 'ALL'

  const targets = {
    ALL: 'ALL',
    PORT: 'Port',
    CENTER: 'Center',
    STARBOARD: 'Starboard',
  }

  let setTarget = ( value ) => target = value

  let getTarget = () => target

  let doSearch = async ( query, cb ) => {
    queryString = (query ?? '').trim()
    searchCallBack = cb

    let results = []

    await searchProduct( target, queryString ).then( res => {
      let exist = false

      if( res.data.list && res.data.list.length ) {
        res.data.list.map( data => { 
          exist = exist ? exist : ( data.name == queryString )
          results.push( { value: data.name, old: true, data: data }) 
        })
      } 

      if( ! exist && queryString != '') {
        results = [ { value: queryString, old: false, data: null } ].concat( results )  
      }

    })

    searchCallBack(results)
  }

  let doAdd = ( productName ) => addProduct(target, productName)

  let doDelete = async ( productId, productName, queryChangeCallback ) => {
    await delProduct( productId ).then( async res => {
      if( res.code == 200 ) {

        // query string change
        queryString = productName==queryString ? '' : queryString

        if( typeof queryChangeCallback == 'function' ) {
          queryChangeCallback( queryString )
        }

        await doSearch( queryString , searchCallBack )
      }
    })
  }

  return {
    targets: targets,
    target: ( value ) => {
      if( value ) {
        setTarget(value)
      } else {
        return getTarget()
      }
    },
    all: () => {
      setTarget( targets.ALL )
    },
    port: () => {
      setTarget( targets.PORT )
    },
    center: () => {
      setTarget( targets.CENTER )
    },
    starboard: () => {
      setTarget( targets.STARBOARD )
    },
    search: doSearch,
    add: doAdd,
    delete: doDelete,
  }
}

