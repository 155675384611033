<template>
    <div v-if="showBackground" class="version">
        <div class="bg"></div>
    </div>
    <el-dialog v-else :visible.sync="showModal" title="New Version" :width="'400px'" :before-close="( done ) => done()" :close-on-click-modal="false" :show-close="false">
      <div class="text-left">The new version is available. Please re-login to get the new updates. We are please advise clear the browser's caches before login.</div>
      <div class="mt-5 text-center">
        <el-button size="mini" plain type="primary" @click="skip">Skip, I will do later</el-button>
        <el-button size="mini" type="primary" @click="reLogin">Yes, I want to logout</el-button>
      </div>
    </el-dialog>
</template>

<script>
import { getVersion, logout } from "@/services/user";
import { clearCookiesCaches } from "@/utils/tools";
import { whiteList } from "@/utils/auth";

export default {
    name: "Version",
    data() {
      return {
        version: '0.0.0',
        skipUpdate: false,
        freezing: false,
        showBackground: false,
        showModal: false,
      }
    },
    mounted() {
      // for testing
      setInterval(() => { 
      //  this.version = '2.0.0' 
        this.verify()
      }, 30000)
    },
    methods: {
      skip() { 
        this.skipUpdate = true 
        this.dialog( false )
      },
      skipWhiteList() { 
        return whiteList.includes( window.location.pathname ) 
      },
      allowRequest() {
        return ! ( this.skipWhiteList() || this.showModal || this.skipUpdate || this.freezing )
      },
      reLogin() {
        this.skip()
        this.handleSignOut()
      },
      handleSignOut() {
        this.showBackground = true
        logout().then(() => {
          clearCookiesCaches();
          location.href = "/login";
        });
      },
      freeze( second ) {
        this.freezing = true
        setTimeout(() => { this.freezing = false }, second * 1000)
      }, 
      verify() {
        if( this.allowRequest() ) {
          this.freeze(5)
          getVersion().then(res => {
            this.crossCheck( res.msg ?? '' )
          })
        }
      },
      init( version ) {
        if(this.version == '0.0.0') {
          this.version = version
        }
      },
      crossCheck(version) {
        this.init( version )
        if(this.version != version) {
          this.dialog( true )
        }
      },
      dialog( onOff ) {
        this.showModal = onOff
      },
    }
  };
</script>
<style scoped lang="scss">
.version {
  width: 100vw;
  height: 100vh;
  top:0;
  position: fixed;

  .bg {
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: absolute;
    opacity: 0.5;
    z-index: 9999;
  }
}
</style>