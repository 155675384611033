import Vue from "vue";
import VueI18n from "vue-i18n";
import message from "./message";

Vue.use(VueI18n);

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  // locale: navigator.language === "zh-CN" ? "zh" : "en", // 设置地区
  locale: "en",
  messages: message, // 设置地区信息
});
export default i18n;
