import axios from "axios";
import { getToken, removeToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";
import { Notification, Message } from "element-ui";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 100000,
});
// 是否显示重新登录
export let isRelogin = { show: false };
// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // 是否需要设置 token
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (res) {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code === 401) {
      Message({
        message: msg,
        type: "error",
        duration: 15000,
        showClose: true,
      });
      if (location.pathname != "/login") {
        location.href = "/login";
        sessionStorage.removeItem("pageStatus");
        sessionStorage.removeItem("editData");
        removeToken();
      }
    } else if (code === 500) {
      Message({
        message: msg,
        type: "error",
        duration: 15000,
        showClose: true,
      });
      return Promise.reject(new Error(msg));
    } else if (code !== 200 && code != 402) {
      Notification.error({
        title: msg,
        duration: 15000,
        showClose: true,
      });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  function (error) {
    let { message } = error;
    if (message == "Network Error") {
      message = "Interface connection exception";
    } else if (message.includes("timeout")) {
      message = "Request timed out";
    } else if (message.includes("Request failed with status code")) {
      message = "interface" + message.substr(message.length - 3) + "error";
    } else if (message.includes("canceled")) {
      return Promise.reject(error);
    }
    Message({
      message: message,
      type: "error",
      duration: 15000,
      showClose: true,
    });
    return Promise.reject(error);
  }
);

export default instance;
