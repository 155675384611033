<template>
  <div v-if="visible" class="oldHost">
    <div class="contents">
      <div class="logo">
        <el-image :src="JPUTLogoHorizontalBlack" class="text-center" />
      </div>
      <p class="line1">The URL have been changed to: <span class="urlColor">{{ newDomain }}</span></p>
      <p class="line2">Current domain will expire on {{ expiredDateFormat }}</p>
      <p class="line3">You will be automatically redirected in {{ seconds }} seconds</p>
      <p class="line4"><el-link :href="newHostLogin">Click here to visit {{ newDomain }} directly</el-link></p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import JPUTLogoHorizontalBlack from "@/assets/JPUTLogoHorizontalBlack.png";

export default {
  name: "OldHost",
  data() {
    return {
      expired: "2024-11-01",
      seconds: 10,
      oldHost: "https://jput.bunkerchain.io",
      // oldHost: "http://localhost:8080",
      newDomain: "jput.vesselconnect.sg",
      JPUTLogoHorizontalBlack,
    }
  },
  created() {
    this.checkHost()
  },
  mounted() {
    this.startCountDown()
  },
  methods: {
    isExpired() {
      return moment().isAfter( this.expired ); 
    },
    isOldHost() {
      return window.location.origin == this.oldHost
    },
    rediectToNewHost() {
      window.location.href = this.newHostLogin
    },
    checkHost() {
      if( this.isOldHost() && this.isExpired() ) {
        return this.rediectToNewHost()
      }
    },
    startCountDown() {
      if( this.isOldHost() ) {
        this.countDown()
      }
    },
    countDown() {
      if( this.seconds >= 0 ) {
        setTimeout(() => {
          if( this.seconds == 0) {
            this.rediectToNewHost()
          } else {
            this.seconds--
            this.countDown()
          }
        }, 1000)
      }
    }
  },
  computed: {
    visible() {
      return this.isOldHost()
    },
    newHostLogin() {
      return 'https://' + this.newDomain + '/login'
    },
    expiredDateFormat() {
      return moment( this.expired ).format('DD MMM YYYY'); 
    },
  }
};
</script>
<style scoped lang="scss">
.oldHost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  background-color: white;
  font-size: 12px;
  font-family: 'Roboto';
  color: #4c565c;
  z-index: 1;
  
  .contents{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    display: block;
    padding: 20px;
    text-align: center;

    .logo {
      width: 120px; 
      margin: 0px auto 20px;
    }

    .line1 {
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 5px;

      .urlColor{
        color: blue;
      }
    }

    .line2 {
      font-size: 13px;
      font-weight: 900;
      margin-bottom: 25px;
    }

    .line3 {
      font-size: 11px;
      font-weight: 900;
    }

    .line4 {
      margin-bottom: 5px;

      ::v-deep .el-link--inner{
        font-size: 11px;
        font-weight: 900;
        color: blue;
        text-decoration: underline;
      }
    }
  }
}
</style>