<template>
  <div style="position: relative">
    <el-dialog class="pdfView" 
      :width="isMobileScreen ? '95vw' : '60vw'" 
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <div v-if="isMobile">
          <el-button icon="el-icon-refresh" round @click="reload" style="position: absolute; top: 13px; right: 50px"></el-button>
          <iframe id="iframeID" :src="'https://docs.google.com/gview?embedded=true&url=' + pdfUrl" width="100%" height="900" frameborder="0" @load="finishLoad"></iframe>
        </div>
        <iframe v-else :src="pdfUrl" width="100%" height="900" frameborder="0" @load="finishLoad"></iframe>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="mini" @click="handdelConfirm">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { setReadBargeLetter, getReadBargeLetter } from "@/utils/auth";
export default {
  name: "LetterToBargeOwner",
  data() {
    return {
      isMobile: false,
      dialogVisible: true,
      pdfUrl: window.location.origin + '/LetterToBargeOwner.pdf',
    };
  },
  computed: {
    ...mapState(["screenType"]),
    isMobileScreen() {
      return this.screenType?.isMobileScreen ?? false;
    },
  },
  created() {
    const flag = getReadBargeLetter()
    this.dialogVisible = flag == 'true' || flag == true 
  },
  mounted() {
    this.detectDevice();

    if (this.pdfUrl) {
      window.addEventListener('resize', this.detectDevice); // Detect changes in window size
      window.addEventListener('orientationchange', this.detectDevice); // Detect changes in orientation
      this.reload();
    }
  },
  beforeDestroy() {
    setReadBargeLetter(false);
    window.removeEventListener('resize', this.detectDevice);
    window.removeEventListener('orientationchange', this.detectDevice);
  },
  methods: {
    finishLoad() {
      setTimeout(() => {
        this.loaded();
      }, 500);
    },
    reload() {
      if (document.getElementById("iframeID")) {
        const tempUrl = document.getElementById("iframeID").src;
        document.getElementById("iframeID").src = "";
        this.$nextTick(() => {
          document.getElementById("iframeID").src = tempUrl;
        })
      }
    },
    detectDevice() {
      // Use userAgent to determine if it's a mobile device
      const userAgent = navigator.userAgent.toLowerCase();
      if (
        userAgent.match(/android/i) ||
        userAgent.match(/webos/i) ||
        userAgent.match(/iphone/i) ||
        userAgent.match(/ipad/i) ||
        userAgent.match(/ipod/i) ||
        userAgent.match(/blackberry/i) ||
        userAgent.match(/windows phone/i)
      ) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    loaded() {
      this.$emit("loaded");
    },
    handdelConfirm() {
      this.dialogVisible = false
      setReadBargeLetter( false )
    },
  },
};
</script>

<style >
.pdfView .el-dialog__body {
  height: 80vh;
  overflow-y: hidden;
}

.pdfView .el-dialog {
  margin-top: 5vh !important;
}

.vue-pdf-embed>div {
  margin-bottom: 8px;
  box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
