import Layout from "@/layout/index.vue";
const UnqualifiedShips = () => import("/src/view/admin/unqualified-ships.vue");
const Jetty = () => import("/src/view/admin/jetty.vue");
const Tank = () => import("/src/view/admin/tank.vue");
const Company = () => import("/src/view/admin/company.vue");
const Ship = () => import("/src/view/admin/ship.vue");
const PilotManagement = () => import("/src/view/admin/pilotManagement");
const Barging = () => import("/src/view/barging");
const ExcelLogistic = () => import("/src/view/excel/logistic");
const ExcelPilot = () => import("/src/view/excel/pilot");
const EquipmentReport = () => import("@/view/equipmentReport");

export let companyRoute = {
  path: "/admin",
  component: Layout,
  redirect: "/admin/company",
  meta: {
    title: "System Administration",
    icon: "system-administration",
    key: "menu.sysAdmin",
  },
  children: [
    {
      path: "company",
      component: Company,
      name: "Company",
      meta: {
        title: "Company Management 1",
        icon: "company",
        key: "menu.cm",
      },
    },
  ],
};
export const requests = [
  {
    path: "/requests",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/view/requests/index.vue"),
        name: "requests",
        meta: {
          key: "menu.requests",
          icon: "request",
        },
      },
    ],
  },
];

const systemRoutes = [
  {
    path: "/",
    redirect: "/admin/unqualified-ships",
  },
  {
    path: "/admin",
    component: Layout,
    redirect: "/admin/unqualified-ships",
    meta: {
      title: "System Administration",
      icon: "system-administration",
      key: "menu.sysAdmin",
    },
    children: [
      {
        path: "unqualified-ships",
        component: UnqualifiedShips,
        name: "Unqualified Ships",
        meta: {
          title: "Unqualified Ships",
          icon: "boat",
          type: "svg",
          key: "menu.us",
        },
      },
      {
        path: "jetty",
        component: Jetty,
        name: "Jetty",
        meta: {
          title: "Jetty Management",
          icon: "jetty",
          key: "menu.jm",
        },
      },
      {
        path: "tank",
        component: Tank,
        name: "Tank",
        meta: {
          title: "Tank Management",
          icon: "tank",
          key: "menu.tm",
        },
      },
      {
        path: "company",
        component: Company,
        name: "Company",
        meta: {
          title: "Company Management 2",
          icon: "company",
          key: "menu.cm",
        },
      },
      {
        path: "ship",
        component: Ship,
        name: "Ship",
        meta: {
          title: "Ship Management",
          icon: "ship",
          key: "menu.sm",
        },
      },
      {
        path: "pilot",
        component: PilotManagement,
        name: "Pilot Management",
        meta: {
          title: "Pilot Management",
          icon: "pilot-management",
          key: "menu.pm",
        },
      },
    ],
  },
];
const certificateRoutes = [
  {
    path: "/",
    redirect: "/certificate/quality",
  },
  {
    path: "/certificate",
    component: Layout,
    children: [
      {
        path: "quality",
        component: () => import("@/view/certificate/quality.vue"),
        name: "quality",
        meta: {
          key: "menu.quality",
          icon: "certificate-of-quality",
        },
      },
    ],
  },
  {
    path: "/certificate",
    component: Layout,
    children: [
      {
        path: "quantity",
        component: () => import("@/view/certificate/quantity.vue"),
        name: "quality",
        meta: {
          key: "menu.quantity",
          icon: "certificate-of-quantity",
        },
      },
    ],
  },
  {
    path: "/certificate",
    component: Layout,
    children: [
      {
        path: "billOfLading",
        component: () => import("@/view/certificate/bill.vue"),
        name: "quality",
        meta: {
          key: "menu.billOfLading",
          icon: "bill-of-lading",
        },
      },
    ],
  },
];
const settingsRoutes = [
  {
    path: "/",
    redirect: "/settings/index",
  },
  {
    path: "/settings",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/view/settings/index.vue"),
        name: "Settings",
        meta: {
          title: "Settings",
          key: "menu.settings",
          icon: "settings",
        },
      },
    ],
  },
];
export const exportDataRoutes = [
  {
    path: "/excel",
    component: Layout,
    shoIcon: false,
    children: [
      {
        path: "logistic/index",
        component: ExcelLogistic,
        name: "Export Data",
        meta: {
          title: "Ecport Data",
          key: "menu.varianceReport",
          icon: "export-data",
        },
      },
      {
        path: "pilot/index",
        component: ExcelPilot,
        name: "Export Data",
        meta: {
          title: "Ecport Data",
          key: "menu.excel",
          icon: "export-data",
        },
      },
    ],
  },
];
export const equipmentReportRoutes = [
  {
    path: "/equipment-report",
    component: Layout,
    shoIcon: false,
    children: [
      {
        path: "index",
        component: EquipmentReport,
        name: "Equipment Report",
        meta: {
          title: "Equipment Report",
          key: "menu.equipmentReport",
          icon: "equipment-report",
        },
      },
    ],
  },
];
export const mooringReportRoutes = [
  {
    path: "/mooring-report",
    component: Layout,
    shoIcon: false,
    children: [
      {
        path: "index",
        component: () => import("@/view/mooringReport/index.vue"),
        name: "MooringReport",
        meta: {
          title: "Mooring Report",
          key: "menu.mooringReport",
          icon: "mooring-report",
        },
      },
    ],
  },
];
export const customerRoutes = [
  {
    path: "/",
    redirect: "/dashboard/index",
  },
  // ...systemRoutes,
  companyRoute,
  {
    path: "/dashboard",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/view/dashboard/index.vue"),
        name: "dashboard",
        meta: {
          key: "menu.dashboard",
          type: "svg",
          icon: "home",
        },
      },
    ],
  },
  {
    path: "/barging",
    component: Layout,
    shoIcon: false,
    children: [
      {
        path: "index",
        component: Barging,
        name: "Barging",
        meta: {
          title: "Barging Nomination",
          key: "menu.bn",
          icon: "barging-nomination",
        },
      },
    ],
  },
  ...certificateRoutes,
  ...mooringReportRoutes,
  {
    path: "*",
    redirect: "/404",
  },
];
export const bargeRoutes = [
  {
    path: "/",
    redirect: "/dashboard/index",
  },
  {
    path: "/admin",
    component: Layout,
    redirect: "/admin/barge",
    meta: {
      title: "System Administration",
      icon: "system-administration",
      key: "menu.sysAdmin",
    },
    children: [
      {
        path: "ship",
        component: Ship,
        name: "Ship",
        meta: {
          title: "Ship Management",
          icon: "ship",
          key: "menu.sm",
        },
      },
    ],
  },
  {
    path: "/dashboard",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/view/dashboard/index.vue"),
        name: "dashboard",
        meta: {
          key: "menu.dashboard",
          type: "svg",
          icon: "home",
        },
      },
    ],
  },
  {
    path: "/barging",
    component: Layout,
    shoIcon: false,
    children: [
      {
        path: "index",
        component: () => import("@/view/barging/index.vue"),
        name: "Barging",
        meta: {
          title: "Barging Nomination",
          key: "menu.bn",
          icon: "barging-nomination",
        },
      },
    ],
  },
  ...certificateRoutes,
  ...equipmentReportRoutes,
  ...mooringReportRoutes,
  {
    path: "*",
    redirect: "/404",
  },
];
export const jettyRoutes = [
  {
    path: "/",
    redirect: "/barging/index",
  },
  {
    path: "/dashboard",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/view/dashboard/index.vue"),
        name: "dashboard",
        meta: {
          key: "menu.dashboard",
          type: "svg",
          icon: "home",
        },
      },
    ],
  },
  ...systemRoutes,
  {
    path: "/barging",
    component: Layout,
    shoIcon: false,
    children: [
      {
        path: "index",
        component: Barging,
        name: "Barging",
        meta: {
          title: "Barging Nomination",
          key: "menu.bn",
          icon: "barging-nomination",
        },
      },
    ],
  },
  ...requests,
  ...certificateRoutes,
  ...equipmentReportRoutes,
  ...exportDataRoutes,
  ...mooringReportRoutes,
  ...settingsRoutes,
  {
    path: "*",
    redirect: "/404",
  },
];
export const adminRoutes = [
  {
    path: "/",
    redirect: "/dashboard/index",
  },
  {
    path: "/dashboard",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/view/dashboard/index.vue"),
        name: "dashboard",
        meta: {
          key: "menu.dashboard",
          type: "svg",
          icon: "home",
        },
      },
    ],
  },
  {
    path: "/admin",
    component: Layout,
    redirect: "/admin/jetty",
    meta: {
      title: "System Administration",
      icon: "system-administration",
      key: "menu.sysAdmin",
    },
    children: [
      {
        path: "jetty",
        component: Jetty,
        name: "Jetty",
        meta: {
          title: "Jetty Management",
          icon: "jetty",
          key: "menu.jm",
        },
      },
      {
        path: "tank",
        component: Tank,
        name: "Tank",
        meta: {
          title: "Tank Management",
          icon: "tank",
          key: "menu.tm",
        },
      },
      {
        path: "company",
        component: Company,
        name: "Company",
        meta: {
          title: "Company Management 3",
          icon: "company",
          key: "menu.cm",
        },
      },
      {
        path: "ship",
        component: Ship,
        name: "Ship",
        meta: {
          title: "Ship Management",
          icon: "ship",
          key: "menu.sm",
        },
      },
    ],
  },
  {
    path: "/auditLog",
    component: Layout,
    redirect: "/auditLog/index",
    meta: {
      title: "audit log",
      icon: "menu-manger",
      key: "menu.auditlog",
    },
    children: [
      {
        path: "index",
        component: () => import("/src/view/auditLog"),
        name: "index",
        meta: {
          title: "Company Management 4",
          icon: "menu-manger",
          key: "menu.auditlog",
        },
      },
    ],
  },
  {
    path: "/barging",
    component: Layout,
    shoIcon: false,
    children: [
      {
        path: "index",
        component: Barging,
        name: "Barging",
        meta: {
          title: "Barging Nomination",
          key: "menu.bn",
          icon: "barging-nomination",
        },
      },
    ],
  },
  ...requests,
  ...certificateRoutes,
  ...equipmentReportRoutes,
  ...exportDataRoutes,
  ...mooringReportRoutes,
  ...settingsRoutes,
  {
    path: "*",
    redirect: "/404",
  },
];

const surveyorRoutes = [
  {
    path: "/",
    redirect: "/dashboard/index",
  },
  companyRoute,
  {
    path: "/barging",
    component: Layout,
    shoIcon: false,
    children: [
      {
        path: "index",
        component: Barging,
        name: "Barging",
        meta: {
          title: "Barging Nomination",
          key: "menu.bn",
          icon: "barging-nomination",
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard/index",
  },
  {
    path: "/dashboard",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/view/dashboard/index.vue"),
        name: "dashboard",
        meta: {
          key: "menu.dashboard",
          type: "svg",
          icon: "home",
        },
      },
    ],
  },
  ...certificateRoutes,
  ...settingsRoutes,
  {
    path: "*",
    redirect: "/404",
  },
];
const routesMap = {
  0: adminRoutes,
  1: jettyRoutes,
  2: customerRoutes,
  3: surveyorRoutes,
  4: bargeRoutes,
};
export function getRoutes(type) {
  return routesMap[type];
}
