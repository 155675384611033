<template>
  <div :class="{ hidden: hidden ,'isMobile-pagination-container':isMobileScreen}" class="flex justify-end pagination-container" >
    <el-pagination v-if="isMobileScreen"
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      size="small"
      pager-count="3"
    />
    <el-pagination v-else
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :pager-count="pagerCount"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { scrollTo } from "@/utils/scroll-to";
import { mapState } from "vuex";
export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      },
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 5 : 7,
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
  computed: {

    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      // document.body.clientWidth <= 590? 3:5;
      return this.screenType?.isMobileScreen??false;
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      if (this.currentPage * val > this.total) {
        this.currentPage = 1;
      }
      this.$emit("pagination", { page: this.currentPage, limit: val });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    // handlePaginationSize(){
    //   var a = document.body.clientWidth <= 590? 3:5;
    //   this.$emit("pagination", { page: val, limit: this.pageSize });
      
    // }
  },
};
</script>

<style scoped lang="scss">
.pagination-container {
  // background: #fcfdff;
  padding: 0px 0 0 0;
  // position: absolute;
  // bottom: 0;
  // width: 100%;
}

.pagination-container.hidden {
  display: none;
}
// FOR MOBILE VIEW
.isMobile-pagination-container {
  padding: 0;
  ::v-deep .el-pagination__total{
    margin-right:2px;
  }
  ::v-deep .el-pagination__jump{
   margin-left:4px;
  }
}

</style>
