import Cookies from "js-cookie";

const MvpTokenKey = "Mvp-Token"
const TokenKey = "Admin-Token";
const TsKey = "Term-Agreement-Key:";
const ReadBargeLetterKey = "ReadBargeLetter";

export const getMvpToken = () => {
  return window.localStorage.getItem(MvpTokenKey);
};
export const setMvpToken = (value) => {
  window.localStorage.setItem(MvpTokenKey, value);
};
export const getToken = () => {
  return window.localStorage.getItem(TokenKey);
};
export const setToken = (value) => {
  window.localStorage.setItem(TokenKey, value);
};
export const clearAll = () => {
  window.localStorage.clear();
};
export function removeMvpToken() {
  return window.localStorage.removeItem(MvpTokenKey);
}
export function removeToken() {
  return window.localStorage.removeItem(TokenKey);
}
export const getCheckTs = (email) => {
  return window.localStorage.getItem(TsKey + email);
};
export const setCheckTs = (email) => {
  window.localStorage.setItem(TsKey + email, true);
};
export function getReadBargeLetter() {
  return window.localStorage.getItem(ReadBargeLetterKey) ?? false;
};
export function setReadBargeLetter(flag) {
  window.localStorage.setItem(ReadBargeLetterKey, flag);
};
export const whiteList = ["/login", "/mvpLogin", "/loginByCode", "/activate", "/register", "/404", "/mvp/authentication"];
